import React, { useState } from 'react';
import classes from './StaticPrivacyPage.module.scss';
import {
  Button,
  FormControl,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography
} from '@mui/material';

import { ReactComponent as ScrollToTop } from '../../assets/svg/ScrollToTop.svg';
import CanAndCo from '../../assets/svg/CanAndCo.png';
import { ReactComponent as InputImage } from '../../assets/svg/InputImage.svg';
import { ReactComponent as Cranchbase } from '../../assets/svg/Cranchbase.svg';
import { ReactComponent as Email } from '../../assets/svg/Email.svg';
import { ReactComponent as Twitter } from '../../assets/svg/Twitter.svg';
import { ReactComponent as YouTube } from '../../assets/svg/YouTube.svg';
import { ReactComponent as Linkedin } from '../../assets/svg/Linkedin.svg';
import { AnalyticsEvent } from '../../utils/analytics';
import { useNavigate } from 'react-router-dom';

export default function FooterPrivacy() {
  const navigate = useNavigate();
  const headersData = [
    {
      label: 'Solution',
      href: '#Solution'
    },
    {
      label: 'Value',
      href: '#Value'
    },
    {
      label: 'Features',
      href: '#Features'
    },
    {
      label: 'Facts',
      href: '#Facts'
    },
    {
      label: 'Contact',
      href: '#Contact'
    }
  ];

  const scrollToTop = () => {
    // Scroll to top logic
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  const handleOpenPrivacy = AnalyticsEvent(
    {
      category: 'landing_page',
      action: 'open_landing_privacy_application_click'
    },
    () => {
      navigate('/privacy');
    }
  );

  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');

  function handleChange(e) {
    setEmail(e.target.value);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const result = validateEmail(email);
      if (result === null && email !== '') {
        setError(true);
      } else {
        setError(false);
      }
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <section className={classes.logoPrivacy}>
      <div className={classes.container}>
        {/* <div onClick={scrollToTop}>
          <ScrollToTop className={classes.scrollToTop} />
        </div> */}
        <div className={classes.contact}>
          <div className={classes.text}>
            AnalystPro Limited
            <br />
            128 City Road
            <br />
            London, EC1V 2NX
            <br />
            United Kingdom
          </div>
          <div className={classes.logoBlock}>
            <div className={classes.opacityText}>Powered By</div>
            <a href="https://canandco.com/" target="_blank" rel="noreferrer">
              <img src={CanAndCo} alt="CanAndCo..." />
            </a>
          </div>
          <div className={classes.logoBlock}>
            <div className={classes.opacityText}>
              Sign up for our newsletter
            </div>
            <div >
              <FormControl className={classes.inputField}>
                <OutlinedInput
                  placeholder="Enter email address"
                  value={email}
                  onChange={handleChange}
                  className={classes.placeholder}
                  endAdornment={
                    <InputAdornment position="end">
                      <InputImage />
                    </InputAdornment>
                  }
                />
              </FormControl>
              {error ? (
                <Typography sx={{ color: '#de005b' }}>
                  The email address you have entered is incorrect
                </Typography>
              ) : null}
            </div>
          </div>
        </div>

        <div className={classes.footer}>
          <div className={classes.footerNav}>
            {headersData.map((header, key) => (
              <Button
                key={key}
                variant="text"
                className={classes.footerText}
                href={header.href}
                onClick={navigate('/')}
              >
                {header.label}
              </Button>
            ))}
          </div>

          <Link
            key={'We’re hiring !'}
            underline="none"
            color="white"
            href="mailto:hello@analystpro.io?subject=AnalystPro%20Careers&body=Please%20send%20us%20your%20CV%20%2F%20resume%20or%20ask%20us%20any%20questions%20%3A)a"
            className={classes.footerTextBold}
          >
            We’re hiring <span className={classes.colorPink}>!</span>
          </Link>

          <Link
            key={'Privacy Policy & Terms'}
            sx={{ color: '#ffffff', opacity: 0.5 }}
            className={classes.opacityLink}
            onClick={handleOpenPrivacy}
          >
            Privacy Policy & Terms
          </Link>

          <div className={classes.footerSocialIcons}>
            <span>
              <a
                href="https://www.crunchbase.com/organization/analystpro"
                target="_blank"
                rel="noreferrer"
              >
                <Cranchbase />
              </a>
            </span>
            <span className="ml29">
              <a
                href="mailto:hello@analystpro.io"
                target="_blank"
                rel="noreferrer"
              >
                <Email />
              </a>
            </span>
            {/* <span className="ml29">
              <a
                href="https://twitter.com/AnalystProLtd"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>
            </span>
            <span className="ml29">
              <a
                href="https://www.youtube.com/channel/UCEdep6rD5pclwsGvfoYIf3g/featured"
                target="_blank"
                rel="noreferrer"
              >
                <YouTube />
              </a>
            </span> */}
            <span className="ml29">
              <a
                href="https://www.linkedin.com/company/analystpro/about/"
                target="_blank"
                rel="noreferrer"
              >
                <Linkedin />
              </a>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
