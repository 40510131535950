import React from 'react';
import classes from './landing-page.module.scss';
import Slider from 'react-slick';

import Gartner from '../../assets/images/Gartner.svg';
import Deloitte from '../../assets/images/Deloitte.svg';
import Google from '../../assets/images/Google.svg';
import MorganStanley from '../../assets/images/MorganStanley.svg';
import QuantumBlack from '../../assets/images/QuantumBlack.svg';
import Cisco from '../../assets/images/Cisco.svg';
import Nationwide from '../../assets/images/Nationwide.svg';
import FounderInstitute from '../../assets/images/FounderInstitute.svg';
import Walmart from '../../assets/images/Walmart.svg';

export function Carousel() {
  const images = [
    {
      url: Gartner
    },
    {
      url: Deloitte
    },
    {
      url: Google
    },
    {
      url: MorganStanley
    },
    {
      url: QuantumBlack
    },
    {
      url: Cisco
    },
    {
      url: Nationwide
    },
    {
      url: FounderInstitute
    },
    {
      url: Walmart
    }
  ];
  const settings = {
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className={classes.paddingCarousel}>
      <Slider {...settings}>
        {images.map((image, key) => (
          <div key={key} className={classes.carousel}>
            <img alt={image.url} key={key} src={image.url} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
