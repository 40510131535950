import React, { useState } from 'react';
import classes from './landing-page.module.scss';
import { Button, Modal, Box, Switch } from '@mui/material';

import {
  initAlbacrossAnalytics,
  initGoogleAnalytics
} from '../../utils/analytics';

export default function CookiesBlock({ setState }) {
  const [open, setOpen] = useState(false);
  const [analytics, setAnalytics] = useState({
    google: true,
    albacross: true
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeSwitch = (event) => {
    setAnalytics({
      ...analytics,
      [event.target.name]: event.target.checked
    });
  };

  const handleCookies = () => {
    removeCookiesBlock();
    localStorage.setItem('useCookiesAnalystPro', JSON.stringify(analytics));
    setOpen(false);
    initGoogleAnalytics(analytics);
    initAlbacrossAnalytics(analytics);
    setState((prevState) => {
      return { ...prevState, actionTaken: true, useCookies: analytics };
    });
  };

  function removeCookiesBlock() {
    let x = document.getElementById('cookies');
    if (x.style.display === 'none') {
      x.style.display = 'block';
    } else {
      x.style.display = 'none';
    }
  }

  const style = {
    p: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    maxWidth: 632,
    outline: 'none'
  };

  return (
    <section id="cookies" className={classes.cookiesBlock}>
      <div className={classes.cookies}>
        <div className={classes.cookiesText}>
          By clicking “Accept all cookies”, you agree to the storing of cookies
          on your device to enhance site navigation, analyse site usage, and
          assist in our marketing efforts.
        </div>
        <div className={classes.cookiesButtons}>
          <Button
            variant="text"
            sx={{ height: '49px' }}
            className={classes.cookiesButtonText}
            onClick={handleOpen}
          >
            Cookies settings
          </Button>
          <Button className={classes.cookiesButton} onClick={handleCookies}>
            Accept all cookies
          </Button>
        </div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <div className={classes.cookieModal}>
              <h1>Cookie configuration</h1>
              <div className={classes.textInfo}>
                Our website uses cookies that are strictly necessary to enable
                you to move around the site or to provide certain basic
                features. We use cookies to provide better functionality of the
                website by storing your preferences. We also use cookies to help
                us to improve the performance of our website and to provide you
                with a better user experience.Read our <a href="https://drive.google.com/file/d/1LzdGCaPzcn9_U736hvuNzdlFX_d2EaLX/view?usp=sharing">Cookie Policy</a> for more details.
              </div>
              <div className={classes.switchContainer}>
                <div className={classes.switchText}>Statistic cookie</div>
                <Switch
                  name="google"
                  checked={analytics.google}
                  onChange={handleChangeSwitch}
                />
              </div>
              <div className={classes.switchContainer}>
                <div className={classes.switchText}>Marketing cookie</div>
                <Switch
                  name="albacross"
                  checked={analytics.albacross}
                  onChange={handleChangeSwitch}
                />
              </div>
              <div className={classes.buttonsContainer}>
                <Button
                  className={classes.modalButton}
                  variant="text"
                  onClick={handleClose}
                >
                  Decline
                </Button>
                <Button className={classes.modalButton} onClick={handleCookies}>
                  Accept
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </section>
  );
}
