import React from 'react';
import classes from './SecondValueBlock.module.scss';
import Slider from 'react-slick';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { ReactComponent as Streamline } from '../../assets/svg/Streamline.svg';
import { ReactComponent as Automate } from '../../assets/svg/Automate.svg';
import { ReactComponent as Trace } from '../../assets/svg/Trace.svg';
import { ReactComponent as CheckCircle } from '../../assets/svg/CheckCircle.svg';

export default function ValueCards() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 0,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };
  return (
    <Slider {...settings} className={classes.cardContainer}>
      <Card sx={{ width: '330px' }} className={classes.card}>
        <CardActionArea>
          <CardContent sx={{ ml: '40px', mt: '28px', mb: '40px', p: '0px' }}>
            <div className={classes.cardBlockImage}>
              <Streamline />
            </div>
            <div className={`${classes.subtitle} ${classes.mb7}`}>
              Streamline
            </div>
            <div className={classes.text}>
              your research and analysis with <br /> our integrated browser
              <br />
              extension and optimise output.
            </div>
            <div className={`${classes.cardBlock} ${classes.mt17}`}>
              <div className={classes.circle}>
                <CheckCircle />
              </div>
              <div className={`${classes.text}`}>Seamless experience</div>
            </div>
            <div className={classes.cardBlock}>
              <div className={classes.circle}>
                <CheckCircle />
              </div>
              <div className={`${classes.text} `}>Effortless collaboration</div>
            </div>
            <div className={classes.cardBlock}>
              <div className={classes.circle}>
                <CheckCircle />
              </div>
              <div className={`${classes.text} `}>
                Purpose built for analysts
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>

      <Card className={classes.card}>
        <CardActionArea>
          <CardContent sx={{ ml: '40px', mt: '28px', mb: '40px', p: '0px' }}>
            <div className={classes.cardBlockImage}>
              <Automate />
            </div>
            <div className={`${classes.subtitle} ${classes.mb7}`}>Automate</div>
            <div className={classes.text}>
              tedious research-related tasks,
              <br /> accelerate your analysis, and
              <br /> save precious time and effort.
            </div>
            <div className={`${classes.cardBlock} ${classes.mt17}`}>
              <div className={classes.circle}>
                <CheckCircle />
              </div>
              <div className={`${classes.text} `}>Quick data extraction</div>
            </div>
            <div className={classes.cardBlock}>
              <div className={classes.circle}>
                <CheckCircle />
              </div>
              <div className={`${classes.text} `}>Smart API integrations</div>
            </div>
            <div className={classes.cardBlock}>
              <div className={classes.circle}>
                <CheckCircle />
              </div>
              <div className={`${classes.text} `}>
                AI assistant (coming soon)
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>

      <Card className={classes.card}>
        <CardActionArea>
          <CardContent sx={{ ml: '40px', mt: '28px', mb: '40px', p: '0px' }}>
            <div className={classes.cardBlockImage}>
              <Trace />
            </div>
            <div className={`${classes.subtitle} ${classes.mb7}`}>Trace</div>
            <div className={classes.text}>
              your insights back to sources,
              <br /> keep track of your data, and
              <br />
              maximise your credibility.
            </div>
            <div className={`${classes.cardBlock} ${classes.mt17}`}>
              <div className={classes.circle}>
                <CheckCircle />
              </div>
              <div className={`${classes.text} `}>End-to-end intel control</div>
            </div>
            <div className={classes.cardBlock}>
              <div className={classes.circle}>
                <CheckCircle />
              </div>
              <div className={`${classes.text} `}>Embedded in workflow</div>
            </div>
            <div className={classes.cardBlock}>
              <div className={classes.circle}>
                <CheckCircle />
              </div>
              <div className={`${classes.text} `}>Single source of truth</div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </Slider>
  );
}
