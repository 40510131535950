import React from 'react';
import classes from './ThirdFeaturesBlock.module.scss';
import { Button } from '@mui/material';
import { ReactComponent as FeatureLine } from '../../assets/svg/FeatureLine.svg';
import FeaturesCards from './FeaturesCards';

export default function ThirdFeaturesBlock({  handleOpenSiteSignup }) {
  return (
    <section className={classes.thirdBlock} id={'Features'}>
      <div className={classes.header}>
        <h1>Features</h1>
        <div className={classes.featureLine}>
          <FeatureLine />
        </div>
      </div>

      <FeaturesCards />
      <div className={classes.featureButtonBlock}>
        <Button
          className={classes.featureBoostResearch}
          onClick={ handleOpenSiteSignup}
        >
          Boost research
        </Button>
      </div>
    </section>
  );
}
