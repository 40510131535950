import React from 'react';
import classes from './FourthFactsBlock.module.scss';

import { ReactComponent as MiddleCheckCircle } from '../../assets/svg/MiddleCheckCircle.svg';
import ResearchFacts from '../../assets/svg/ResearchFacts.png';

export default function FourthFactsBlock() {
  return (
    <section className={classes.fourthBlock} id={'Wins'}>
      <div className={classes.container}>
        <div className={classes.factsHeader}>
          <div className={classes.researchText}>
            <h1>Research to insight,</h1>
          </div>
          <div className={classes.researchTextPink}>
            <h1>without the stress</h1>
          </div>
        </div>

        <div className={classes.factsContainer}>
          <div className={classes.factsList}>
            <div className={classes.textBlock}>
              <div className={classes.headerBlock}>
                <div>
                  <MiddleCheckCircle />
                </div>
                <div className={classes.subtitle}>No messy research</div>
              </div>

              <div className={classes.text}>
                wasting hours manually gathering, collating and organising
                critical data and scattered research.
              </div>
            </div>

            <div className={classes.textBlock}>
              <div className={classes.headerBlock}>
                <div>
                  <MiddleCheckCircle />
                </div>
                <div className={classes.subtitle}>No missing sources</div>
              </div>

              <div className={classes.text}>
                wasting hours manually connecting inconsistent data sources and
                <br />
                analysis to insight.
              </div>
            </div>

            <div className={classes.textBlock}>
              <div className={classes.headerBlock}>
                <div>
                  <MiddleCheckCircle />
                </div>
                <div className={classes.subtitle}>No annoying tasks</div>
              </div>

              <div className={classes.text}>
                wasting hours manually <br /> homogenising data in different
                <br /> standards to enable analysis.
              </div>
            </div>
          </div>
        </div>

        <div className={classes.researchFacts}>
          <img src={ResearchFacts} alt="ResearchFacts..." />
        </div>
      </div>
    </section>
  );
}
