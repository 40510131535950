import React, { useEffect } from 'react';
import classes from './StaticPrivacyPage.module.scss';
import { ReactComponent as LogoIcon } from '../../assets/svg/FifthBlockLogo.svg';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FooterPrivacy from './FooterPrivacy';
import TermsOfUse from './TermsOfUse';

export default function StaticPrivacyPage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <header className={classes.appbar}>
        <div
          id={'Logo'}
          className={classes.logo}
          onClick={() => {
            navigate('/');
          }}
        >
          <LogoIcon />
        </div>
      </header>

      <section className={classes.privacy}>
        <div className={classes.privacyBlock}>
          <div className={classes.privacyTitle}>
            <h1>Privacy Policy</h1>
          </div>
          <div className={classes.paragraph}>
            <h3>Introduction</h3>
          </div>
          <div className={classes.paragraph}>
            At AnalystPro Limited (“AnalystPro”), we take the issue of your
            privacy very seriously, which is why we work diligently to ensure
            that we have policies and procedures in place, allowing us to
            continue our mission to liberate analysts from tedious tasks and
            make research fun again. The following privacy policy was developed
            in accordance with the Data Protection Act 2018 and explains what
            personal information we collect or receive from you, how we process
            such personal information, and what we do to keep your personal
            information safe and secure.
          </div>
          <div className={classes.paragraph}>
            Please carefully read the following to understand our views and
            practices regarding your personal information and how we will treat
            it.
          </div>

          <div className={classes.paragraph}>
            <h3>Who we are and what we do</h3>
          </div>
          <div className={classes.paragraph}>
            We are AnalystPro, the company on a mission to reduce the time
            analysts and researchers spend gathering, collating and tracing
            research data. We are registered at UK Companies House and our
            company number is 13791810 and our ICO registration number is
            ZB341393. Our office is based at 128 City Road, London, United
            Kingdom, EC1V 2NX.
          </div>
          <div className={classes.paragraph}>
            We collect personal information from the following types of people
            to allow us to undertake our business:
          </div>
          <div className={classes.paragraph}>
            <ul>
              <li>Customers who install the browser extension;</li>
              <li>Third parties and suppliers;</li>
              <li>
                Our own employees, contractors, partners, and shareholders;
              </li>
              <li>
                Any other person or entity who expresses interest in our
                services through our website.
              </li>
            </ul>
          </div>
          <div className={classes.paragraph}>
            We collect information about you to carry out our core business and
            ancillary activities.
          </div>
          <div className={classes.paragraph}>
            <h3>Information you give to us or we collect about you</h3>
          </div>
          <div className={classes.paragraph}>
            This is information about you that you give to us by registering on
            our website, software and/or services (https://analystpro.io/), as
            well as corresponding with us by phone, e-mail, or other forms of
            communication. It includes information you provide when you register
            to use our services via registration forms on our website, when you
            make a payment to us, enter a promotion or survey and when you
            report a problem with our website.
          </div>
          <div className={classes.paragraph}>
            The information you give to us or we collect about you may include
            your full name, e-mail address, mobile phone number, date of birth,
            office address and bank details, among others.
          </div>
          <div className={classes.paragraph}>
            We may also collect personal information if you register for the
            Website using a third party social network account (e.g., LinkedIn,
            Facebook, and Twitter). For example, the Website may allow you to
            login using your social network account credentials. We may collect
            the user name associated with that social media account and any
            information or content you have permitted the social media network
            to share with us, such as your profile picture, email address, and
            birthday. The information we collect may depend on the privacy
            settings you have with the social network site, so please review the
            privacy statement or policy of the applicable social network site.
            When you access the Website through your social network account, you
            are authorising us to collect and use your information in accordance
            with this privacy statement.
          </div>

          <div className={classes.paragraph}>
            <h3>Information we collect about you when you visit our website</h3>
          </div>
          <div className={classes.paragraph}>
            With regard to each of your visits to our website, we will
            automatically collect the following information:
          </div>
          <div className={classes.paragraph}>
            <ul>
              <li>
                Technical information, including the Internet Protocol (IP)
                address used to connect your computer to the Internet, your
                login information (if applicable), browser type and version,
                browser plug-in types and versions, operating system, and
                platform; and
              </li>
              <li>
                Information about your visit, including the full Uniform
                Resource Locators (URL) and how you interact with our website.
              </li>
            </ul>
          </div>

          <div className={classes.paragraph}>
            <h3>
              Purposes of the processing and the legal basis for the processing
            </h3>
          </div>
          <div className={classes.paragraph}>
            We use information held about you in the following ways:
          </div>
          <div className={classes.paragraph}>
            <ul>
              <li>
                To provide you or our clients or other third parties with
                services, and this includes using your personal data in
                correspondence relating to those services.
              </li>
              <li>To conduct due diligence checks relating to the services.</li>
              <li>Recruitment and business development.</li>
              <li>
                To carry out our obligations arising from any contracts we
                intend to enter into or have entered into between you and us and
                to provide you with the information and services that you
                request from us or which we think will be of interest to you.
              </li>
              <li>
                To provide you with information about other services we offer
                which are similar to those that you already utilise, have been
                provided with, or you have enquired about.
              </li>
              <li>
                Our legal basis for the processing of personal information is
                our legitimate business interests, described in more detail
                below, although we will also rely on contract, legal obligation,
                and consent for specific uses of personal information
              </li>
              <li>
                We will rely on legal obligation if we are legally required to
                hold information about you to fulfil our legal obligations.
              </li>
            </ul>
          </div>
          <div className={classes.paragraph}>
            <h3>Our legitimate business interests</h3>
          </div>
          <div className={classes.paragraph}>
            We process your personal information on a number of lawful bases,
            including your consent, where provided. One such lawful basis is
            “legitimate interests” which means that we are permitted to process
            your personal information where:
          </div>
          <div className={classes.paragraph}>
            <ul>
              <li>We have a genuine and legitimate reason;</li>
              <li>We are not harming any of your rights and interests.</li>
            </ul>
          </div>
          <div className={classes.paragraph}>
            <h3>Consent</h3>
          </div>
          <div className={classes.paragraph}>
            Should we want or need to rely on consent to lawfully process your
            personal information we will request that you provide your consent
            verbally, by email, or by an online process for the specific
            activity for which we require consent and your response will be
            recorded on our system. Where consent is the lawful basis for
            processing your personal information, you have the right to withdraw
            your consent to this particular processing at any time.
          </div>

          <div className={classes.paragraph}>
            <h4>Other uses of your personal information:</h4>
          </div>
          <div className={classes.paragraph}>
            <ul>
              <li>To notify you about changes to our service;</li>
              <li>
                To ensure that content from our website is presented in the most
                effective manner for you and for your computer;
              </li>
              <li>
                To administer our website, software and/or services and for
                internal operations, including troubleshooting, data analysis,
                testing, research, statistical, and survey purposes;
              </li>
              <li>
                To improve our website, software and/or services to ensure that
                our service is provided in the most effective manner for you;
              </li>
              <li>
                As part of our efforts to keep our website and software safe and
                secure;
              </li>
              <li>To process your payment;</li>
              <li>
                To measure or understand the effectiveness of advertising we
                provide to you and others, and to deliver relevant advertising
                to you;
              </li>
              <li>
                To make suggestions and recommendations to you and other users
                of our website, software and/or services about goods or services
                that may interest you or them.
              </li>
            </ul>
          </div>
          <div className={classes.paragraph}>
            We do not undertake automated decision-making or profiling. We do
            use our computer systems to search and identify personal information
            in accordance with parameters set by a person. A person will always
            be involved in the decision-making process.
          </div>

          <div className={classes.paragraph}>
            <h3>Cookies</h3>
          </div>
          <div className={classes.paragraph}>
            Our website and uses cookies to distinguish you from other users of
            our website. This helps us to provide you with a positive and
            tailored experience when you browse our website and further helps to
            improve our website. For detailed information regarding the cookies
            we use and the purposes for which we use them, see our Cookie Notice{' '}
            <Link href=" https://drive.google.com/file/d/1LzdGCaPzcn9_U736hvuNzdlFX_d2EaLX/view?usp=sharing">
              https://drive.google.com/file/d/1LzdGCaPzcn9_U736hvuNzdlFX_d2EaLX/view?usp=sharing.
            </Link>
          </div>

          <div className={classes.paragraph}>
            <h3>Disclosure of your information inside and outside of the UK</h3>
          </div>
          <div className={classes.paragraph}>
            We work with the following third parties and share your personal
            information with them to help us deliver the best possible service
            to you:
          </div>
          <div className={classes.paragraph}>
            <ul>
              <li>
                AWS, a cloud computing platform where our website is hosted. For
                more, please see{' '}
                <Link href="https://aws.amazon.com/privacy/">
                  https://aws.amazon.com/privacy/
                </Link>
              </li>
              <li>
                Dropbox, a file hosting service. For more, please see{' '}
                <Link href="https://www.dropbox.com/en_GB/privacy">
                  https://www.dropbox.com/en_GB/privacy
                </Link>
              </li>
              <li>
                Google workspace, a collection of cloud computing, productivity
                and collaboration tools, software and products. For more, please
                see{' '}
                <Link href="https://policies.google.com/privacy?hl=en-US">
                  https://policies.google.com/privacy?hl=en-US
                </Link>{' '}
              </li>
              <li>
                Google analytics, a web analytics service. For more please see{' '}
                <Link href="https://policies.google.com/privacy?hl=en-UK">
                  https://policies.google.com/privacy?hl=en-UK
                </Link>
              </li>
              <li>
                Hotjar, a suite of analytic tools to assist in the gathering of
                qualitative data. For more, please see{' '}
                <Link href="https://www.hotjar.com/legal/policies/privacy/">
                  https://www.hotjar.com/legal/policies/privacy/
                </Link>{' '}
              </li>
              <li>
                Mixpanel, a business analytics service company. For more, please
                see,{' '}
                <Link href="https://mixpanel.com/legal/privacy-policy/">
                  https://mixpanel.com/legal/privacy-policy/
                </Link>
              </li>
              <li>
                Albacross, a lead generating software. For more, please see,{' '}
                <Link href="https://albacross.com/privacy-policy/">
                  https://albacross.com/privacy-policy/
                </Link>
              </li>
              <li>
                Business partners, consultants, and other service providers for
                provision of AnalystPro service to you.
              </li>
              <li>
                Analytics and search engine providers that assist us in the
                improvement and optimisation of our app.
              </li>
            </ul>
          </div>
          <div className={classes.paragraph}>
            We will disclose your personal information to third parties where:
          </div>

          <div className={classes.paragraph}>
            <ul>
              <li>
                We sell or buy any business or assets; we will disclose your
                personal information to the prospective seller or buyer of such
                business or assets.
              </li>
              <li>
                AnalystPro, or substantially all of its assets are acquired by a
                third party; personal information regarding AnalystPro’s
                customers will be among the transferred assets.
              </li>
              <li>
                We are under a duty to disclose or share your personal
                information in order to comply with any legal obligation, or in
                order to enforce or apply our terms of service, website terms of
                use, or other agreements; or to protect the rights, property, or
                safety of AnalystPro, our customers, or others. This includes
                exchanging information with other companies and organisations
                for the purposes of fraud protection and credit risk reduction.
              </li>
            </ul>
          </div>

          <div className={classes.paragraph}>
            <h3>Where we store and process your personal information</h3>
          </div>
          <div className={classes.paragraph}>
            The personal information that we collect from you is stored on AWS
            cloud servers within the UK. All information you provide to us is
            stored on these secure servers and any payment transactions are
            encrypted. Where we have given you (or where you have chosen) a
            password which enables you to access certain parts of our website,
            software and/or services, you are responsible for keeping this
            password confidential. We ask you not to share a password with
            anyone.
          </div>
          <div className={classes.paragraph}>
            Unfortunately, the transmission of information via the Internet is
            not completely secure. Although we will do our best to protect your
            personal information, we cannot guarantee the security of your
            personal information transmitted to our website or software; any
            transmission is undertaken at your own risk. We have put in place
            appropriate security measures to prevent your personal information
            from being accidentally lost, used, or accessed in an unauthorised
            way, altered, or disclosed. In addition, we limit access to your
            personal information to those employees, agents, contractors, and
            other third parties who have a business need to know. They will only
            process your personal information on our instructions and they are
            subject to a duty of confidentiality.
          </div>
          <div className={classes.paragraph}>
            Whenever we transfer your personal information out of the UK, we
            ensure a similar degree of protection is afforded to it by ensuring
            at least one of the following safeguards is implemented:
          </div>
          <div className={classes.paragraph}>
            <ul>
              <li>
                We will only transfer your personal information to countries
                that have been deemed to provide an adequate level of protection
                for personal data;
              </li>
              <li>
                Where we use certain service providers, we may use specific
                contracts approved for use in the UK which give personal
                information the same protection it has in the UK.
              </li>
            </ul>
          </div>

          <div className={classes.paragraph}>
            <h3>Retention of your personal information</h3>
          </div>
          <div className={classes.paragraph}>
            We will only retain your personal information for as long as
            reasonably necessary to fulfil the purposes we collected it for,
            including for the purposes of satisfying any legal, regulatory, tax,
            accounting, or reporting requirements. We may retain your personal
            information for a longer period in the event of a complaint or if we
            reasonably believe there is a prospect of litigation in respect to
            our relationship with you.
          </div>
          <div className={classes.paragraph}>
            To determine the appropriate retention period for personal
            information, we consider the amount, nature, and sensitivity of the
            personal information, the potential risk of harm from unauthorised
            use or disclosure of your personal information, the purposes for
            which we process your personal information and whether we can
            achieve those purposes through other means, and the applicable
            legal, regulatory, tax, accounting or other requirements.
          </div>

          <div className={classes.paragraph}>
            <h3>Your rights</h3>
          </div>
          <div className={classes.paragraph}>
            You have the right to request that we not process your personal
            information for marketing purposes. We will usually inform you
            (before collecting your personal information) if we intend to use
            your personal information for such purposes or if we intend to
            disclose your information to any third party for such purposes. In
            such circumstances, we will collect express consent from you if
            legally required to do so prior to using your personal information
            for marketing purposes.
          </div>
          <div className={classes.paragraph}>
            Our website may, from time to time, contain links to and from the
            websites of our partner networks, advertisers, and affiliates. If
            you follow a link to any of these websites, please note that these
            websites have their own privacy policies and that we do not accept
            any responsibility or liability for such policies. Please check
            these policies before you submit any personal information to these
            websites.
          </div>

          <div className={classes.paragraph}>
            <h3>
              The UK Data Protection Act 2018 provides you with the following
              rights:
            </h3>
          </div>
          <div className={classes.paragraph}>
            <ul>
              <li>
                <b>Request correction </b> of the personal information that we
                hold about you. This enables you to have any incomplete or
                inaccurate information we hold about you corrected.
              </li>
              <li>
                <b> Request erasure</b> of your personal information. You may
                ask us to delete or remove personal information where we do not
                have a lawful basis for continuing to process it. You also have
                the right to ask us to delete or remove your personal
                information where you have exercised your right to object to
                processing.
              </li>
              <li>
                {' '}
                <b> Object to processing</b> of your personal information where
                we are relying on a legitimate interest (or those of a third
                party) and there is something about your particular situation
                which makes you want to object to processing on this ground. You
                also have the right to object where we are processing your
                personal information for direct marketing purposes.
              </li>
              <li>
                {' '}
                <b> Request the restriction of processing</b> of your personal
                information. You may ask us to suspend the processing of
                personal information about you; for example, if you want us to
                confirm the accuracy of, or reasons for processing, your
                personal information.
              </li>
              <li>
                {' '}
                <b>Request the transfer</b> of your personal information to
                another party in certain formats, if practicable.
              </li>
              <li>
                {' '}
                <b> Make a complaint</b> to a supervisory body; in the United
                Kingdom, the Information Commissioner’s Office. The ICO can be
                contacted through this link:{' '}
                <Link href=" https://ico.org.uk/concerns/">
                  {' '}
                  https://ico.org.uk/concerns/
                </Link>
              </li>
            </ul>
          </div>

          <div className={classes.paragraph}>
            <h3>Access to information</h3>
          </div>
          <div className={classes.paragraph}>
            The Data Protection Act 2018 give you the right to access your
            personal information. We encourage you to contact us to ensure that
            your personal information is accurate and complete.
          </div>
          <div className={classes.paragraph}>
            A data subject access request should be submitted to
            hello@analystpro.io. In accordance with the Data Protection Act
            2018, no fee will apply to such an access request.
          </div>

          <div className={classes.paragraph}>
            <h3>Changes to our privacy notice</h3>
          </div>
          <div className={classes.paragraph}>
            Any changes we make to our privacy notice will be posted on this
            page and, where appropriate, you will be notified of such changes by
            e-mail. Please check back frequently for any updates or changes to
            our privacy notice.
          </div>

          <TermsOfUse />

          <div className={classes.paragraph}>
            <h3>Contact</h3>
          </div>
          <div className={classes.paragraph}>
            We hope that we have shared with you all the information you need,
            but in the event that you seek further information or if you have
            any questions, please contact us via email at hello@analystpro.io.
          </div>
          <div className={classes.paragraph}>
            Responsible for Policy: Pangolin DPC Ltd.
            <br />
            <br />
            Date Last Updated: June 2022
          </div>
        </div>
      </section>
      <FooterPrivacy />
    </div>
  );
}
