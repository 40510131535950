import React, { useState, useEffect } from 'react';
import classes from './landing-page.module.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as LogoIcon } from '../../assets/svg/FifthBlockLogo.svg';
import DynamicPresentation from '../../assets/gif/DynamicPresentation.gif';
import { Carousel } from './Carousel';
import { ReactComponent as OurTeam } from '../../assets/svg/OurTeam.svg';

import MobileNavScreen from './MobileNavScreen';
import SecondValueBlock from './SecondValueBlock';
import ThirdFeaturesBlock from './ThirdFeaturesBlock';
import FourthFactsBlock from './FourthFactsBlock';
import FifthContactBlock from './FifthContactBlock';
import CookiesBlock from './CookiesBlock';

import {
  AnalyticsEvent,
  initAlbacrossAnalytics,
  initGoogleAnalytics
} from '../../utils/analytics';

export function LandingPage() {
  const navigate = useNavigate();
  const [state, setState] = useState(() => {
    const useCookies = JSON.parse(localStorage.getItem('useCookiesAnalystPro'));

    return {
      actionTaken: useCookies ? true : false,
      useCookies: useCookies
    };
  });

  //Add Albacross scripts to the bottom of landing page
  useEffect(() => {
    if (state.useCookies !== null) {
      initAlbacrossAnalytics(state.useCookies);
      initGoogleAnalytics(state.useCookies);
    }
  }, []);

  const headersData = [
    {
      label: 'Solution',
      href: '#Solution'
    },
    {
      label: 'Value',
      href: '#Value'
    },
    {
      label: 'Features',
      href: '#Features'
    },
    {
      label: 'Wins',
      href: '#Wins'
    },
    {
      label: 'Contact',
      href: '#Contact'
    }
  ];

  const handleOpenSiteSignin = AnalyticsEvent(
    {
      category: 'landing_page',
      action: 'open_analystpro_signIn_application_click'
    },
    () => {
      window.open('https://app.analystpro.io/signin');
    }
  );

  const handleOpenSiteSignup = AnalyticsEvent(
    {
      category: 'landing_page',
      action: 'open_analystpro_signUp_application_click'
    },
    () => {
      window.open('https://app.analystpro.io/signup');
    }
  );

  const handleOpenPrivacy = AnalyticsEvent(
    {
      category: 'landing_page',
      action: 'open_landing_privacy_application_click'
    },
    () => {
      navigate('/privacy');
    }
  );

  return (
    <div>
      <section className={classes.firstBlock} id={'Solution'}>
        <div className={classes.container}>
          <MobileNavScreen
            handleOpenSiteSignin={handleOpenSiteSignin}
            handleOpenSiteSignup={handleOpenSiteSignup}
          />
          <div className={classes.header}>
            <div className={classes.headerLogo}>
              <LogoIcon />
            </div>

            <div className={classes.headerNavButtons}>
              {headersData.map((header, key) => (
                <Button
                  key={key}
                  variant="text"
                  className={classes.navText}
                  href={header.href}
                >
                  {header.label}
                </Button>
              ))}
            </div>
            <div className={classes.headerButtons}>
              <Button
                color="white"
                variant="outlined"
                onClick={handleOpenSiteSignin}
                sx={{ width: '100px', height: '48px', mr: '25px' }}
              >
                Log in
              </Button>
              <Button
                sx={{ width: '150px', height: '48px' }}
                onClick={handleOpenSiteSignup}
              >
                Get started
              </Button>
            </div>
          </div>

          <div className={classes.contentContainer}>
            <div className={classes.textBlock}>
              <div className={classes.title}>
                <h1>
                  Remove tedious tasks <br /> and boost research productivity by
                  150%
                </h1>
              </div>
              <div className={classes.subtitle}>
                <p>
                  Analysts and consultants waste more than 200 hours a year due
                  to inefficient manual tasks, swapping applications, and
                  inability to connect source data. Time that could be spent
                  crushing it.
                </p>
              </div>
              <div className={classes.group}>
                <Button
                  className={classes.boostButton}
                  onClick={handleOpenSiteSignup}
                >
                  Boost research
                </Button>
              </div>
            </div>
            <div className={classes.DynamicPresentation}>
              <img src={DynamicPresentation} alt="DynamicPresentation..." />
            </div>
          </div>

          <div className={classes.carouselContainer}>
            <div className={classes.ourTeam}>
              <OurTeam />
            </div>
            <Carousel />
          </div>
        </div>
      </section>

      <SecondValueBlock />

      <ThirdFeaturesBlock handleOpenSiteSignup={handleOpenSiteSignup} />

      <FourthFactsBlock />

      <FifthContactBlock
        handleOpenSiteSignup={handleOpenSiteSignup}
        handleOpenPrivacy={handleOpenPrivacy}
      />

      {!state.actionTaken && <CookiesBlock setState={setState} />}
    </div>
  );
}
