import ReactGA4 from 'react-ga4';

export function initGoogleAnalytics(analytics) {
  // Initialize Google Analytics
  if (analytics.google) {
    ReactGA4.initialize([
      {
        trackingId: `${process.env.REACT_APP_GA4_MANAGEMENT_ID}`,
        gaOptions: { debug: process.env.NODE_ENV === 'development' }
      }
    ]);
  }
}

export function AnalyticsEvent(analyticsOptions, decoratedCallback) {
  return function (...args) {
    ReactGA4.event(analyticsOptions);
    return decoratedCallback.apply(this, args);
  };
}

export function initAlbacrossAnalytics(analytics) {
  if (analytics.albacross) {
    const addAlbacrossWindowVariableScript = document.createElement('script');
    const addAlbacrossTrackingScript = document.createElement('script');

    addAlbacrossWindowVariableScript.innerText = 'window._nQc="89553934";';
    addAlbacrossTrackingScript.src = 'https://serve.albacross.com/track.js';
    addAlbacrossTrackingScript.async = true;

    document.body.appendChild(addAlbacrossWindowVariableScript);
    document.body.appendChild(addAlbacrossTrackingScript);
    return () => {
      document.body.removeChild(addAlbacrossWindowVariableScript);
      document.body.removeChild(addAlbacrossTrackingScript);
    };
  }
}
