import React from 'react';
import classes from './SecondValueBlock.module.scss';
import { ReactComponent as Speed } from '../../assets/svg/Speed.svg';
import { ReactComponent as Line } from '../../assets/svg/Line.svg';
import { ReactComponent as BigCheckCircle } from '../../assets/svg/BigCheckCircle.svg';
import ResearchTable from '../../assets/images/ResearchTable.png';
import ValueCards from './ValueCards';

export default function SecondValueBlock() {
  return (
    <section className={classes.secondBlock} id={'Value'}>
      <div className={classes.secondBlockContainer}>
        <div className={classes.content}>
          <div className={classes.valueHeader}>
            <div className={classes.analystTextPink}>
              <h1>Effortlessly integrated</h1>
            </div>
            <div className={classes.analystText}>
              <h1>into your workflow</h1>
            </div>
          </div>

          <div className={classes.valueCardsContainer}>
            <ValueCards />
          </div>

          <div className={classes.speedBlock}>
            <div className={classes.speed}>
              <Speed />
            </div>
            <div className={classes.speedContent}>
              <div className={classes.speedTitle}>
                Be <span>150%</span> faster with AnalystPro
              </div>
              <div className={classes.line}>
                <Line />
              </div>
            </div>
          </div>
          <div className={classes.imageContainer}>
            <img src={ResearchTable} alt="" />
            <div className={classes.imageRightContent}>
              <div className={classes.imageText}>
                <div>
                  by <span>easily </span> collecting research data into your
                  workflow
                </div>
                <div className={classes.rightLine}>
                  <Line />
                </div>
              </div>

              <div className={classes.bigCheckCircle}>
                <BigCheckCircle />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
