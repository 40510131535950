import React from 'react';
import classes from './ThirdFeaturesBlock.module.scss';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { ReactComponent as Engine } from '../../assets/svg/thirdBlockFeatures/Engine.svg';
import { ReactComponent as Insights } from '../../assets/svg/thirdBlockFeatures/Insights.svg';
import { ReactComponent as Extractor } from '../../assets/svg/thirdBlockFeatures/Extractor.svg';
import { ReactComponent as Archive } from '../../assets/svg/thirdBlockFeatures/Archive.svg';
import { ReactComponent as Comments } from '../../assets/svg/thirdBlockFeatures/Comments.svg';
import { ReactComponent as Data } from '../../assets/svg/thirdBlockFeatures/Data.svg';

import { ReactComponent as EngineWhite } from '../../assets/svg/thirdBlockFeatures/EngineWhite.svg';
import { ReactComponent as InsightsWhite } from '../../assets/svg/thirdBlockFeatures/InsightsWhite.svg';
import { ReactComponent as ExtractorWhite } from '../../assets/svg/thirdBlockFeatures/ExtractorWhite.svg';
import { ReactComponent as ArchiveWhite } from '../../assets/svg/thirdBlockFeatures/ArchiveWhite.svg';
import { ReactComponent as CommentsWhite } from '../../assets/svg/thirdBlockFeatures/CommentsWhite.svg';
import { ReactComponent as DataWhite } from '../../assets/svg/thirdBlockFeatures/DataWhite.svg';

export default function FeaturesCards() {
  return (
    <div className={classes.cardContainerFeatures}>
      <Card className={classes.featuresBlock}>
        <CardActionArea>
          <CardContent className={classes.featureContent}>
            <div className={classes.titleBlock}>
              <div className={classes.title}>Research Engine</div>
              <div className={classes.icon}>
                <Engine />
              </div>
              <div className={classes.iconHover}>
                <EngineWhite />
              </div>
            </div>
            <div className={classes.text}>
              Start your resarch project and accelerate your journey from
              research to insight with AnalystPro's powerful engine.
            </div>
          </CardContent>
        </CardActionArea>
      </Card>

      <Card className={classes.featuresBlock}>
        <CardActionArea>
          <CardContent className={classes.featureContent}>
            <div className={classes.titleBlock}>
              <div className={classes.title}>Traceable Insights</div>
              <div className={classes.icon}>
                <Insights />
              </div>
              <div className={classes.iconHover}>
                <InsightsWhite />
              </div>
            </div>
            <div className={classes.text}>
              Effortlessly track your research findings with an auditable data
              trail back to the source to ensure credible conclusions.
            </div>
          </CardContent>
        </CardActionArea>
      </Card>

      <Card className={classes.featuresBlock}>
        <CardActionArea>
          <CardContent className={classes.featureContent}>
            <div className={classes.titleBlock}>
              <div className={classes.title}>Data Extractor</div>
              <div className={classes.icon}>
                <Extractor />
              </div>
              <div className={classes.iconHover}>
                <ExtractorWhite />
              </div>
            </div>
            <div className={classes.text}>
              Easily gather data by using our handy browser extension embedded
              into your workflow to save information quickly.
            </div>
          </CardContent>
        </CardActionArea>
      </Card>

      <Card className={classes.featuresBlock}>
        <CardActionArea>
          <CardContent className={classes.featureContent}>
            <div className={classes.titleBlock}>
              <div className={classes.title}>Knowledge Archive</div>
              <div className={classes.icon}>
                <Archive />
              </div>
              <div className={classes.iconHover}>
                <ArchiveWhite />
              </div>
            </div>
            <div className={classes.text}>
              Automatically organise your findings on the platform which acts as
              a secure, auditable and reusable source of truth.
            </div>
          </CardContent>
        </CardActionArea>
      </Card>

      <Card className={classes.featuresBlock}>
        <CardActionArea>
          <CardContent className={classes.featureContent}>
            <div className={classes.titleBlock}>
              <div className={classes.title}>Tags & Comments</div>
              <div className={classes.icon}>
                <Comments />
              </div>
              <div className={classes.iconHover}>
                <CommentsWhite />
              </div>
            </div>
            <div className={classes.text}>
              Seamlessly structure your research and insights as you extract
              data and give meaning to your findings.
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card className={classes.featuresBlock}>
        <CardActionArea>
          <CardContent className={classes.featureContent}>
            <div className={classes.titleBlock}>
              <div className={classes.title}>Quantitative Data</div>
              <div className={classes.icon}>
                <Data />
              </div>
              <div className={classes.iconHover}>
                <DataWhite />
              </div>
            </div>
            <div className={classes.text}>
              Intuitively save quantitative data and plug it into your model to
              boost your analysis for greater impact.
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}
