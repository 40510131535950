import React from 'react';
import classes from './StaticPrivacyPage.module.scss';

export default function TermsOfUse() {
  return (
    <>
      <div className={classes.privacyTitle}>
        <h1>Terms of use</h1>
      </div>
      <div className={classes.paragraph}>
        <h3>Introduction</h3>
      </div>
      <div className={classes.paragraph}>
        These terms and conditions apply between you, the user of this Website,
        other downloadable applications, and other services provided by us
        (collectively, the “Service”) and AnalystPro Limited (“AnalystPro”), the
        owner and operator of this Service. Please read these terms and
        conditions carefully, as they affect your legal rights. Your agreement
        to comply with and be bound by these terms and conditions is deemed to
        occur upon your first use of the Service. If you do not agree to be
        bound by these terms and conditions, you should stop using the Service
        immediately.
      </div>
      <div className={classes.paragraph}>
        In these terms and conditions, <b>“User”</b> or <b>“Users”</b> means any
        third party that accesses the AnalystPro Service, and is not either (i)
        employed by AnalystPro and acting in the course of their employment or
        (ii) engaged as a consultant or otherwise providing services to
        AnalystPro and accessing the Service in connection with the provision of
        such services.
      </div>
      <div className={classes.paragraph}>
        You must be at least 18 years of age to use this Service. By using the
        Service and agreeing to these terms and conditions, you represent and
        warrant that you are at least 18 years of age.
      </div>

      <div className={classes.paragraph}>
        <h3>Intellectual property and acceptable use</h3>
      </div>

      <div className={classes.paragraph}>
        <ol>
          <li>
            For purposes of these terms and conditions “Content” means any text,
            graphics, images, audio, video, software, data compilations, page
            layout, underlying code and software and any other form of
            information capable of being stored in a computer that appears on or
            forms part of this Service, including any such content uploaded by
            Users. All Content included on the Website or Service, unless
            uploaded by Users, is the property of AnalystPro, our affiliates or
            other relevant third parties. In these terms and conditions, By
            continuing to use the Service you acknowledge that such Content is
            protected by copyright, trademarks, database rights and other
            intellectual property rights. Nothing on this site shall be
            construed as granting, by implication, estoppel, or otherwise, any
            license or right to use any trademark, logo or service mark
            displayed on the site without the owner’s prior written permission
          </li>
          <li>
            We will create statistical, aggregated data relating to our users
            and the Service for analytical purposes. Aggregated data includes
            data derived from Personal Information and obtained by AnalystPro
            from other sources in aggregated, anonymous form and does not
            identify any individual (such data is referred to as “Aggregate
            Information”). Subject to applicable laws and regulations, we use
            Aggregate Information to understand our customers and to develop,
            improve and/or market our Services. We may provide Aggregate
            Information to third parties.
          </li>
          <li>
            You may, for your own personal, non-commercial use only, do the
            following:
            <div>
              <ul>
                <li>
                  retrieve, display and view the Content on a computer screen
                </li>
                <li>
                  download and store the Content in electronic form on a storage
                  device such as a USB or CD (but not on any server or other
                  storage device connected to a network)
                </li>
                <li>print one copy of the Content</li>
              </ul>
            </div>
          </li>
          <li>
            You must not otherwise reproduce, modify, copy, distribute or use
            for commercial purposes any Content without the written permission
            of AnalystPro.
          </li>
          <li>
            You acknowledge that you are responsible for any Content you may
            submit via the Website or Service, including the legality,
            reliability, appropriateness, originality and copyright of any such
            Content. You may not upload to, distribute or otherwise publish
            through the Website or Service any Content that (i) is confidential,
            proprietary, false, fraudulent, libellous, defamatory, obscene,
            threatening, invasive of privacy or publicity rights, infringing on
            intellectual property rights, abusive, illegal or otherwise
            objectionable; (ii) may constitute or encourage a criminal offence,
            violate the rights of any party or otherwise give rise to liability
            or violate any law; or (iii) may contain software viruses, political
            campaigning, chain letters, mass mailings, or any form of “spam.”
            You may not use a false email address or other identifying
            information, impersonate any person or entity or otherwise mislead
            as to the origin of any content. You may not upload commercial
            content onto the Website or Service.
          </li>
          <li>
            You represent and warrant that you own or otherwise control all the
            rights to the Content you post; that the Content is accurate; that
            use of the Content you supply does not violate any provision of
            these terms and conditions and will not cause injury to any person;
            and that you will indemnify AnalystPro for all claims resulting from
            Content you supply.
          </li>
        </ol>
      </div>

      <div className={classes.paragraph}>
        <h3>Prohibited use</h3>
      </div>
      <div className={classes.paragraph}>
        You may not use the Service for any of the following purposes:
        <ol>
          <li>
            in any way which causes, or may cause, damage to the Service or
            interferes with any other person’s use or enjoyment of the Service;
          </li>
          <li>
            in any way which is harmful, unlawful, illegal, abusive, harassing,
            threatening or otherwise objectionable or in breach of any
            applicable law, regulation, governmental order;
          </li>
          <li>
            making, transmitting or storing electronic copies of Content
            protected by copyright without the permission of the owner.
          </li>
        </ol>
      </div>

      <div className={classes.paragraph}>
        <h3>Registration</h3>
      </div>
      <div className={classes.paragraph}>
        <ol>
          <li>
            You must ensure that the details provided by you on registration or
            at any time are correct and complete.
          </li>
          <li>
            You must inform us immediately of any changes to the information
            that you provide when registering by updating your personal details
            to ensure we can communicate with you effectively.
          </li>
          <li>
            We may suspend or cancel your registration with immediate effect for
            any reasonable purposes or if you breach these terms and conditions.
          </li>
          <li>
            You may cancel your registration at any time by informing us in
            writing to the address at the end of these terms and conditions. If
            you do so, you must immediately stop using the Service. Cancellation
            or suspension of your registration does not affect any statutory
            rights.
          </li>
        </ol>
      </div>

      <div className={classes.paragraph}>
        <h3>Password and security</h3>
      </div>
      <div className={classes.paragraph}>
        <ol>
          <li>
            When you create an account on this Website or Service, you will be
            asked to create a password, which you should keep confidential and
            not disclose or share with anyone.
          </li>
          <li>
            If we have reason to believe that there is or is likely to be any
            misuse of the Service or breach of security, we may require you to
            change your password or suspend your account.
          </li>
        </ol>
      </div>

      <div className={classes.paragraph}>
        <h3>Password and security</h3>
      </div>
      <div className={classes.paragraph}>
        <ol>
          <li>
            When you create an account on this Website or Service, you will be
            asked to create a password, which you should keep confidential and
            not disclose or share with anyone.
          </li>
          <li>
            If we have reason to believe that there is or is likely to be any
            misuse of the Service or breach of security, we may require you to
            change your password or suspend your account.
          </li>
        </ol>
      </div>

      <div className={classes.paragraph}>
        <h3>Disclaimers</h3>
      </div>
      <div className={classes.paragraph}>
        The content and functionality on the Service is provided with the
        understanding that AnalystPro is not herein engaged in rendering
        professional advice or services to you, no service content is intended
        to serve as or shall be deemed investment, legal, tax, accounting or
        other regulated advice, and that you shall remain solely responsible for
        your use of all service content and acknowledge that any reliance upon
        the service content shall be entirely at your sole option and risk. All
        content and functionality on the service is provided “as is,” without
        warranty of any kind, either express or implied, including, without
        limitation, implied warranties of merchantability and fitness for a
        particular purpose. AnalystPro make no warranties, express or implied,
        as to the ownership, accuracy, or adequacy of the service content.
        AnalystPro shall have no liability or responsibility for any information
        published on linked websites, contained in any user submissions
        published on the service, or provided by third parties. Neither
        AnalystPro nor its third-party content providers shall be liable for any
        indirect, incidental, consequential, or punitive damages or losses or
        for lost revenues or profits, whether advised of the possibility of such
        damages or losses and regardless of the theory of liability.
      </div>

      <div className={classes.paragraph}>
        <h3>Indemnification</h3>
      </div>
      <div className={classes.paragraph}>
        You hereby indemnify, defend, and hold harmless AnalystPro and all of
        its predecessors, successors, parents, subsidiaries, affiliates,
        officers, directors, shareholders, investors, employees, agents,
        representatives, and attorneys and their respective heirs, successors,
        and assigns (“AnalystPro Indemnified Parties”) from and against any and
        all liability, expenses, costs, or other losses (“Losses”) incurred by
        AnalystPro and/or AnalystPro Indemnified Parties in connection to any
        claims arising out of your use of the Service and/or any breach by you
        of these terms and conditions, including the representations, warranties
        and covenants you made, if any, by agreeing to these terms and
        conditions. AnalystPro reserves the right to assume, at its own expense,
        the exclusive defense and control of any matter otherwise subject to
        indemnification by you.
      </div>

      <div className={classes.paragraph}>
        <h3>Third-party websites & Providers</h3>
      </div>
      <div className={classes.paragraph}>
        We may provide links to third-party websites, and some of the Content
        appearing to be on this Website is in fact supplied, supported, or
        provided directly or indirectly by third parties, for example, in
        instances of framing of third-party websites or incorporation through
        framesets of content supplied by third-party servers. AnalystPro has no
        responsibility for these third-party websites, which are governed by the
        terms of use and privacy policies, if any, of the applicable third-party
        content providers.
      </div>

      <div className={classes.paragraph}>
        <h3>Privacy Policy and Cookies Policy</h3>
      </div>
      <div className={classes.paragraph}>
        Use of the Service is also governed by our Privacy Policy and Cookies
        Policy, which are incorporated into these terms and conditions by this
        reference.
      </div>

      <div className={classes.paragraph}>
        <h3>General</h3>
      </div>
      <div className={classes.paragraph}>
        <ol>
          <li>
            You may not transfer any of your rights under these terms and
            conditions to any other person. We may transfer our rights under
            these terms and conditions where we reasonably believe your rights
            will not be affected.
          </li>
          <li>
            These terms and conditions may be varied by us from time to time.
            Such revised terms will apply to the Website from the date of
            publication. Users should check the terms and conditions regularly
            to ensure familiarity with the then current version.
          </li>
          <li>
            These terms and conditions together with the Privacy Policy and
            Cookies Policy contain the whole agreement between the parties
            relating to its subject matter and supersede all prior discussions,
            arrangements or agreements that might have taken place in relation
            to the terms and conditions.
          </li>
          <li>
            The Contracts (Rights of Third Parties) Act 1999 shall not apply to
            these terms and conditions and no third party will have any right to
            enforce or rely on any provision of these terms and conditions.
          </li>
          <li>
            If any court or competent authority finds that any provision of
            these terms and conditions (or part of any provision) is invalid,
            illegal or unenforceable, that provision or part-provision will, to
            the extent required, be deemed to be deleted, and the validity and
            enforceability of the other provisions of these terms and conditions
            will not be affected.
          </li>
          <li>
            Unless otherwise agreed, no delay, act or omission by a party in
            exercising any right or remedy will be deemed a waiver of that, or
            any other, right or remedy.
          </li>
          <li>
            This agreement shall be governed by and interpreted according to the
            law of England and Wales and all disputes arising under the
            agreement (including non-contractual disputes or claims) shall be
            subject to the exclusive jurisdiction of the English and Welsh
            courts.
          </li>
        </ol>
      </div>

      <div className={classes.paragraph}>
        <h3>AnalystPro details</h3>
      </div>
      <div className={classes.paragraph}>
        AnalystPro is a company incorporated in the UK with registered number
        13791810 whose registered address is 128 City Road, London, United
        Kingdom, EC1V 2NX and it operates the Website https://analystpro.io/.
      </div>
      <div className={classes.paragraph}>
        You can contact AnalystPro by email on hello@analystpro.io.
      </div>
    </>
  );
}
