import React, { useState } from 'react';
import classes from './landing-page.module.scss';
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Link
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/svg/CloseIcon.svg';
import { ReactComponent as MobileHeaderIcon } from '../../assets/svg/LogoIcon.svg';
import { ReactComponent as MobileHeaderButton } from '../../assets/svg/MobileHeaderButton.svg';

export default function MobileNavScreen({
  handleOpenSiteSignin,
  handleOpenSiteSignup
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const OpenNav = () => {
    setIsDrawerOpen(true);
  };

  const CloseNav = () => {
    setIsDrawerOpen(false);
  };

  const openSiteSignin = () => {
    handleOpenSiteSignin();
    setIsDrawerOpen(false);
  };

  const openSiteSignup = () => {
    handleOpenSiteSignup();
    setIsDrawerOpen(false);
  };

  return (
    <AppBar color="black" className={classes.mobile}>
      <Toolbar id="mobileHeaderId" className={classes.mobileHeader}>
        <div className={classes.mobileHeaderIcon}>
          <MobileHeaderIcon />
        </div>
        <div className={classes.mobileButton} onClick={OpenNav}>
          <MobileHeaderButton />
        </div>
      </Toolbar>
      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        className={classes.drawerContainer}
      >
        <List className={classes.drawer}>
          <div className={classes.closeIcon} onClick={CloseNav}>
            <CloseIcon />
          </div>
          <ListItem sx={{ mb: '35px' }} button onClick={CloseNav}>
            <ListItemText
              primary={
                <Link
                  href="#Solutions"
                  underline="none"
                  className={classes.listitem}
                >
                  Solution
                </Link>
              }
            />
          </ListItem>

          <ListItem sx={{ mb: '35px' }} button onClick={CloseNav}>
            <ListItemText
              primary={
                <Link
                  href="#Value"
                  underline="none"
                  className={classes.listitem}
                >
                  Value
                </Link>
              }
            />
          </ListItem>

          <ListItem sx={{ mb: '35px' }} button onClick={CloseNav}>
            <ListItemText
              primary={
                <Link
                  href="#Features"
                  underline="none"
                  className={classes.listitem}
                >
                  Features
                </Link>
              }
            />
          </ListItem>

          <ListItem sx={{ mb: '35px' }} button onClick={CloseNav}>
            <ListItemText
              primary={
                <Link
                  href="#Wins"
                  underline="none"
                  className={classes.listitem}
                >
                  Wins
                </Link>
              }
            />
          </ListItem>

          <ListItem sx={{ mb: '35px' }} button onClick={CloseNav}>
            <ListItemText
              primary={
                <Link
                  href="#Contact"
                  underline="none"
                  className={classes.listitem}
                >
                  Contact
                </Link>
              }
            />
          </ListItem>
          <div iid="bottom" className={classes.bottomButtons}>
            <Button
              color="white"
              variant="outlined"
              className={classes.first}
              onClick={openSiteSignin}
            >
              Log in
            </Button>
            <Button className={classes.second} onClick={openSiteSignup}>
              Get started
            </Button>
          </div>
        </List>
      </Drawer>
    </AppBar>
  );
}
