import './App.css';
import React from 'react';
import { MuiThemeProvider } from './views/AppTheme';
import { AppRoutes } from './AppRoutes';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { initGoogleAnalytics } from "./utils/analytics";

function App() {
  const useCookies = JSON.parse(localStorage.getItem('useCookiesAnalystPro'));
  if (useCookies !== null) {
    initGoogleAnalytics(useCookies);
  }

  return (
    <MuiThemeProvider>
      <AppRoutes />
    </MuiThemeProvider>
  );
}

export default App;
